<template>
    <section>
        <div class="py-4">
            <timercomponent v-model="tipo_fecha" @refresh="montar()" />
            <div class="row mx-0 pl-4 align-items-center">
                <div class="col-auto">
                    <div class="bg-light-f5 d-middle text-general f-18 border br-4 py-3 px-3">
                        <i class="icon-cliente f-18 text-general mr-2" />
                        <b>{{ agregarSeparadoresNumero(clientesCedis) }} {{ $config.cliente + 's' }}</b>
                    </div>
                </div>
                <div class="col-auto px-4 f-18 text-general f-600">
                    Tipo de {{ $config.cliente }}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5">
                    <el-select v-model="id_tipo" placeholder="Seleccionar tipo" class="w-100" size="small" @change="agregarTipos">
                        <el-option
                        v-for="item in tiposClientes"
                        :key="item.id"
                        :label="item.tipo"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto">
                    <div class="bg-general br-8 text-white px-4 cr-pointer d-middle-center" style="height:32px;" @click="tiposRefrescar">
                        Cargar
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-auto px-5" />
                <div class="col-auto px-5" />
                <div class="col pl-2">
                    <div class="row mx-0">
                        <div class="col-12 d-middle px-4 my-1 mt-2">
                            <el-checkbox v-model="sin_tipo" class="check-red">
                                <span class="ml-1">Todos los clientes sin clasificación</span>
                            </el-checkbox>
                        </div>
                        <div v-if="!sin_tipo" class="col-12 mt-1">
                            <div class="row mx-0">
                                <div v-for="(data,d) in tiposClientesSeleccionados" :key="d" class="bg-light-f5 br-20 px-2 border d-middle mr-1 f-14 mb-1">
                                    {{ data.tipo }} <i class="icon-cancel ml-3 f-15 text-general cr-pointer" @click="eliminarTipo(d)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4" />
            <div class="row mx-3 f-18 f-600 py-3 br-t-8 px-5  text-general bg-white">
                Relación de ventas con {{ $config.cliente + 's' }}
            </div>
            <div class="row mx-3 px-4 position-relative bg-white mb-4 br-b-8">
                <!-- Filtro -->
                <cargando v-if="cargandoComparativos" />
                <div class="px-3 m-3">
                    <div class="row cr-pointer" :class="filtro_relacion==4?'text-general':''" @click="filtro_relacion = 4">
                        <p class="p-2">Hoy</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==4?'bg-general3 br-12':'bg-whitesmoke'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==1?'text-general':''" @click="filtro_relacion = 1">
                        <p class="p-2">Última semana</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==1?'bg-general3 br-12':'bg-whitesmoke'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==2?'text-general':''" @click="filtro_relacion = 2">
                        <p class="p-2">Último mes</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==2?'bg-general3 br-12':'bg-whitesmoke'" />
                    </div>
                </div>
                <!-- Relaciones -->
                <div v-for="(rel, idx) in relaciones" :key="idx" class="br-12 mx-2 my-1" :class="idx>1 && rel.ids.length > 0 ? 'cr-pointer' : ''" @click="modalRelacion(idx,rel.ids,rel.nombre)">
                    <div
                    class="br-12 p-2 text text-2 text-center px-4 bg-fondo text-general"
                    style="width:150px;height:60px;"
                    >
                        {{ rel.nombre }}
                    </div>
                    <div
                    class="row mx-0 my-1 px-2"
                    :class="{ 'text-general':(rel.porcentaje>0), 'text-general-red':(rel.porcentaje<0), '':(rel.porcentaje==0) }"
                    >
                        <div class="col px-0 text-center">
                            <i :class="{ 'icon-up':(rel.porcentaje>0), 'icon-down':(rel.porcentaje<0), '':(rel.porcentaje==0) }" />
                            {{ rel.porcentaje>0?'+':'' }}{{ rel.porcentaje }}%
                        </div>
                    </div>
                    <div class="row mx-0 my-1 f-22 px-2 text-muted">
                        <div class="col px-0 text-center">
                            {{ idx == 1 ? separadorNumero(rel.valor,1) : agregarSeparadoresNumero(rel.valor) }}
                        </div>
                    </div>
                </div>
            </div>
            <graficas ref="grafica" :tipo-fecha="tipo_fecha" />

            <div class="row mx-3 mt-4 br-t-12 pt-3 bg-white f-600 text-general px-5">
                Crecimiento de {{ $config.cliente + 's' }} en los últimos {{ chart_Crecimiento.dataset.source.length }} meses
            </div>
            <!-- Grafica -->
            <div>
                <cargando v-if="cargandoCrecimiento" />
                <chartBarras :datos="crecimientoClientes" :chart="chart_Crecimiento" />
            </div>
            <div class="row mx-3 mt-4 mb-2">
                <div class="col-auto d-middle text-general f-15">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general f-14 px-4" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoMetricas" mini />
            </div>
            <div class="row mx-0 justify-center mb-4">
                <div class="col-5">
                    <div class="row mx-0 justify-center">
                        <div class="d-middle bg-white shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="modalPrimeraVez(1)">
                            <div class="col-auto">
                                <img :src="url_primera" alt="" />
                            </div>
                            <div class="col text-general f-17">
                                {{ $config.cliente + 's' }} que ingresaron por primera vez al sistema
                            </div>
                            <div class="col-auto text-general d-middle-center f-20 f-600">
                                {{ agregarSeparadoresNumero(primerosAccesos.length) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row mx-0 justify-center">
                        <div class="d-middle bg-white shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="modalPrimeraVez(2)">
                            <div class="col-auto">
                                <img :src="url_productos" alt="" />
                            </div>
                            <div class="col text-general f-17">
                                {{ $config.cliente + 's' }} que realizaron su primera compra
                            </div>
                            <div class="col-auto text-general d-middle-center f-20 f-600">
                                {{ agregarSeparadoresNumero(primerosPedidos.length) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-3 br-8 py-2 bg-white">
                <p class="col-12 f-18 px-5 py-3 f-600 text-general mb-3">
                    Ventas por {{$config.vendedor}}
                </p>
                <div class="col px-5 py-3 mb-5">
                    <div class="row mx-0">
                        <div v-for="(leechero, l) in leecheros_ventas.slice(0,8)" :key="l" class="col-auto px-2">
                            <div class="card-ventas tres-puntos text-center">
                                <el-tooltip placement="bottom" :content="leechero.nombre" effect="light">
                                    <img :src="leechero.foto" class="br-8 obj-cover" width="90" height="90" />
                                </el-tooltip>
                                <p class="my-2 text-general text-center f-14">
                                    {{ agregarSeparadoresNumero(leechero.cantidad) }}
                                </p>
                                <p class="text-general f-600 f-14 text-center tres-puntos">
                                    {{ separadorNumero(leechero.total) }}
                                </p>
                                <p class="my-2 text-general f-14 text-center">
                                    {{ agregarSeparadoresNumero(leechero.porcentaje_total, 2) }}%
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="leecheros_ventas.length > 8" class="col-auto">
                    <div
                    class="border bg-general3 text-white f-14 br-12 pt-3 text-center py-2 cr-pointer"
                    style="width:90px;height:90px;"
                    @click="ventasPorLeecheros('Ventas por leecheros')"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 px-5 mt-4 pt-3 br-t-12 bg-white f-18 f-600 text-general">
                Compras por tipo de {{ $config.cliente }}
            </div>
            <chartBarras :datos="pedidosTipos" :chart="chart_tipos" />
            <div class="row mx-3 px-5 mt-4 pt-3 bg-white br-t-12 f-18 f-600 text-general">
                Pedidos recibidos por cedis
            </div>
            <chartBarras :datos="pedidosCedis" :chart="chart_cedis" />
            <div class="row mx-3 px-5 pt-3 bg-white br-t-12 mt-4 f-18 f-600 text-general">
                Pedidos recibidos por las {{ $config.vendedor }} de acuerdo a la distancia de los {{ $config.cliente }}
            </div>
            <chartBarras :datos="pedidosRecibidos" :chart="chart_pedidos" class="mb-4" />
            <div class="row mx-3 br-8 bg-white px-5 py-3 mb-4">
                <div class="col-12 px-0">
                    <p class="text-general f-18 f-600">
                        Categorias mas vendidas
                    </p>
                </div>
                <div class="col px-5 py-3 mb-5">
                    <div class="row mx-0">
                        <div v-for="(data, d) in categorias_dinero.slice(0,8)" :key="d" class="col-auto px-2">
                            <div class="br-12 border d-middle-center px-3 f-12" style="width:125px;height:75px;background-color:#F8F9FF;">
                                {{ data.nombre }}
                            </div>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.total) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.porcentaje_total,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="categorias_dinero.length > 8" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-2 cr-pointer"
                    @click="verCategorias('Categorias mas vendidas')"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <!--*----*----* Compras por genero *----*----*-->
            <div class="row mx-3 bg-white py-3 br-t-8 px-5 f-600 f-18 text-general">
                Compradores por género
            </div>
            <div class="row mx-3 bg-white py-3 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${generos[0].porcentaje}%;`" />
                            <div class="h-100 bg-general2" :style="`width:${generos[1].porcentaje}%;`" />
                            <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${generos[2].porcentaje}%;`" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 br-b-8 bg-white justify-content-center py-3 mb-4">
                <!-- Compradores Mujer -->
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Mujer {{ agregarSeparadoresNumero(generos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Compradores Hombre -->
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (2).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Hombre {{ agregarSeparadoresNumero(generos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Compradores sin definir -->
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/astronaut (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Otro {{ agregarSeparadoresNumero(generos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-3 py-3 px-5 text-general f-600 f-18 bg-white br-t-8">
                Compradores por edad
            </div>
            <div class="row mx-3 px-5 bg-white">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <el-tooltip v-for="(data, i) in compradores_edad" :key="i" placement="top" :content="`${data.edad}: ${separadorNumero(data.total)}`" effect="light">
                            <div v-if="data.estado != 11" class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5" :class="backgroundEdad(data.estado)" :style="`width:${data.porcentaje}%;`" />
                            <div v-else class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5 bg-dark" :style="`width:${data.porcentaje}%;`" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="row mx-3 px-5 bg-white br-b-8 py-3 justify-center mb-4">
                <div v-for="(data, i) in compradores_edad" :key="i" class="col-2 px-2 my-2 text-muted2" style="min-height:95px;">
                    <div class="w-100 h-100 border br-12 px-3">
                        <p class="f-17 tres-puntos py-1">
                            <span class="f-600" :class="estiloEdad(data.estado)"> {{ data.edad }}:   {{ data.porcentaje }}% </span>
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ separadorNumero(data.total) }}
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }} Pedidos
                        </p>
                    </div>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-3 px-5 bg-white br-t-8 py-3 text-general f-600 f-18">
                Compradores por dispositivo
            </div>
            <div class="row mx-3 bg-white py-2 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${dispositivos[1].porcentaje}%;`" />
                            <div class="h-100 bg-bg-general2" :style="`width:${dispositivos[2].porcentaje}%;`" />
                            <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${dispositivos[0].porcentaje}%;`" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 py-3 mb-4 br-b-8 bg-white justify-content-center">
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/android.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Android {{ agregarSeparadoresNumero(dispositivos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/apple.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                iOS {{ agregarSeparadoresNumero(dispositivos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/world-wide-web.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Web {{ agregarSeparadoresNumero(dispositivos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-3 py-4 bg-white br-t-8">
                <div class="col-6 mx-0 px-5 text-general f-600 f-18">
                    Comportamiento de compra de los {{ $config.cliente + 's' }}
                </div>
                <div class="col-6 ml-auto mx-0 px-5" style="width:170px;">
                    <p class="mb-0 text-general text-right f-18">
                        {{ agregarSeparadoresNumero(total_clientes_paginado) }}/{{ agregarSeparadoresNumero(total_clientes) }} Clientes
                    </p>
                </div>
            </div>
            <!-- Tabla -->
            <div class="row mx-3 align-items-centerjustify-center" style="height:50px;">
                <div class="col-3 d-middle-center cr-pointer text-general f-500">
                    {{ $config.cliente + 's' }}
                </div>
                <div class="col d-middle-center text-center f-500">
                    <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(1)">
                        <div class="position-relative mx-1">
                            <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                            <div class="position-relative" :class="containerClass(1)">
                                <i class="f-18 position-absolute" :class="iconFiltro(1)" style="" />
                            </div>
                        </div>
                        Pedidos totales
                    </div>
                </div>
                <div class="col d-middle-center text-center f-500">
                    <div class="d-middle-center text-general cr-pointer justify-center" @click="ordenar(2)">
                        <div class="position-relative mx-1">
                            <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                            <div class="position-relative" :class="containerClass(2)">
                                <i class="f-18 position-absolute" :class="iconFiltro(2)" style="" />
                            </div>
                        </div>
                        Compras totales
                    </div>
                </div>
                <div class="col d-middle-center text-center f-500">
                    <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(3)">
                        <div class="position-relative mx-1">
                            <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                            <div class="position-relative" :class="containerClass(3)">
                                <i class="f-18 position-absolute" :class="iconFiltro(3)" style="" />
                            </div>
                        </div>
                        Pedidos en la fecha
                    </div>
                </div>
                <div class="col d-middle-center text-center f-500">
                    <div class="d-middle-center text-general cr-pointer justify-center" @click="ordenar(4)">
                        <div class="position-relative mx-1">
                            <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                            <div class="position-relative" :class="containerClass(4)">
                                <i class="f-18 position-absolute" :class="iconFiltro(4)" style="" />
                            </div>
                        </div>
                        Compras en la fecha
                    </div>
                </div>
                <div class="col d-middle-center text-center f-500">
                    <div class="d-middle-center text-general cr-pointer justify-center" @click="ordenar(5)">
                        <div class="position-relative mx-1">
                            <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                            <div class="position-relative" :class="containerClass(5)">
                                <i class="f-18 position-absolute" :class="iconFiltro(5)" style="" />
                            </div>
                        </div>
                        Ticket Promedio
                    </div>
                </div>
            </div>
            <div v-for="(cliente,c) in clientes_paginado" :key="c" class="row mx-3 align-items-center border-bottom bg-white p-1 cr-pointer" style="min-height:69px;" @click="AbrirInfoClientes(cliente)">
                <div class="col-3 px-1">
                    <div class="d-middle justify-left">
                        <img :src="cliente.avatar" class="border" style="width:45px;height:45px;border-radius:50%;object-fit:cover;" />
                        <div class="mx-3 py-2">
                            <p class="mb-0 f-18 text-general">
                                {{ cliente.nombre }}
                            </p>
                            <p v-if="cliente.antiguedad" class="mb-0 text-muted3 f-14">
                                {{ $config.cliente }} desde {{ cliente.antiguedad }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col px-1 text-center">
                    <p class="mb-0 f-22 text-general">
                        {{ agregarSeparadoresNumero(cliente.cantidad_total) }}
                    </p>
                </div>
                <div class="col px-1 text-center">
                    <el-tooltip class="item" effect="light" placement="bottom">
                        <div slot="content" class="text-center" style="width:80px;">
                            <p>Promedio total</p>
                            {{ separadorNumero(cliente.promedio_total) }}
                        </div>
                        <p class="mb-0 f-22 text-general">
                            {{ separadorNumero(cliente.total_total) }}
                        </p>
                    </el-tooltip>
                </div>
                <div class="col px-1 text-center">
                    <p class="mb-0 f-22 text-general">
                        {{ agregarSeparadoresNumero(cliente.cantidad_fecha) }}
                    </p>
                </div>
                <div class="col px-1 text-center">
                    <p class="mb-0 f-22 text-general">
                        {{ separadorNumero(cliente.total_fecha) }}
                    </p>
                </div>
                <div class="col px-1 text-center">
                    <p class="mb-0 f-22 text-general">
                        {{ separadorNumero(cliente.promedio_fecha) }}
                    </p>
                </div>
            </div>
            <!-- Tabla -->
            <div class="row mx-3 bg-white br-b-8 justify-content-end px-5 py-3">
                <div class="col-auto mr-5" style="width:170px;" />
                <div class="col-3 d-middle-center">
                    <div v-if="total_clientes > total_clientes_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="agregar_mas_clientes">
                        Ver más
                    </div>
                    <div v-if="total_clientes > total_clientes_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="verTodos">
                        Ver todos
                    </div>
                </div>
                <div class="col-3 d-middle-center" />
                <div class="col-auto ml-3">
                    <p class="mb-0 text-general f-18">
                        {{ agregarSeparadoresNumero(total_clientes_paginado) }}/{{ agregarSeparadoresNumero(total_clientes) }} Clientes
                    </p>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal-info-clientes ref="infoClientes" />
        <modal-ver-categorias ref="modalVerCategorias" />
        <modal-ver-categorias ref="ventasPorLeecheros" />
        <modalDetalleRelacion ref="modalDetalleRelacion" />
        <modal-compra-ingreso ref="modalCompraIngreso" titulo="Clientes que ingresaron y/o compraron por primera vez" />
        <!-- Modales -->
    </section>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general2')
let colorGeneral3 = getComputedStyle(document.documentElement).getPropertyValue('--text-general')
let colorGeneral4 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')


import Clientes from "~/services/clientes"
import MetricasTeams from "~/services/metricas_teams"


export default {
    components: {
        modalInfoClientes: () => import ('./partials/modalInfoClientes'),
        graficas: () => import ('./graficas'),
        modalDetalleRelacion: () => import ('./partials/modalDetalleRelacion'),
        modalCompraIngreso: () => import ('../../estadisticas/accesoUsuarios/partials/modalCompraIngreso')
    },
    props: {
        leecheros : {
            type: Array,
            default: () => []
        },
    },
    data(){
        return{
            cargandoComparativos: true,
            cargandoCrecimiento: true,
            cargandoMetricas: true,
            order: true,
            filtro_relacion: 1,
            tipo_fecha: 1,
            tipo_relacion: 1,
            relaciones: [
                { nombre: 'Cantidad de pedidos', porcentaje: 8.7, valor: 145, ids: [] },
                { nombre: 'Ticket promedio', porcentaje: -12.5, valor: 5457000, ids: [] },
                { nombre: 'Total clientes', porcentaje: -12.5, valor: 15, ids: [] },
                { nombre: 'Clientes nuevos', porcentaje: 5.4, valor: 18, ids: [] },
                { nombre: 'Clientes recurrentes', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Clientes perdidos', porcentaje: -4.1, valor: 4, ids: [] },
            ],
            clicksPedidos: 1,
            clientesCedis: 1,
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            pedidosTipos: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Tipo con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Tipo con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            pedidosCedis: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Cedis con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Cedis con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            pedidosRecibidos: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Rango con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Rango con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            crecimientoClientes: [
                { nombre: 'Clientes totales', valor: 0, color: 'bg-gr-general', formato: 2 },
                { nombre: 'Mes con mayor crecimiento', valor: 0, fecha: '' ,color: 'bg-gr-pink', formato: 2 }
            ],
            chart_Crecimiento : {
                backgroundColor: '#fff',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            show: true,
                            backgroundColor: '#fff',
                            color: '#556677',
                            borderColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(0,0,0,0)',
                            shadowOffsetY: 0
                        },
                        lineStyle: {
                            width: 1
                        }
                    },
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#5c6c7c'
                    },
                    padding: [5, 5],
                    extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
                },
                xAxis: [
                    {
                        type: 'category',
                        // data: ['Ener', 'Feb', 'Mar', 'abril', 'may','jun'],
                        axisLine: {
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#556677'
                            },
                            fontSize: 12,
                            margin: 15
                        },
                        axisPointer: {
                            label: {
                                padding: [0, 0, 10, 0],
                                margin: 15,
                                fontSize: 12,
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#fff'
                                    }, {

                                        offset: 0.86,
                                        color: '#fff'
                                    }, {
                                        offset: 0.86,
                                        color: colorGeneral
                                    }, {
                                        offset: 1,
                                        color: colorGeneral
                                    }],
                                    global: false
                                }
                            }
                        },
                        boundaryGap: false
                    }
                ],
                yAxis: [
                    {
                        name: 'Clientes',
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        splitLine: {
                            show: true
                        }
                    }, {
                        type: 'value',
                        position: 'right',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            },
                            formatter: '{value}'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        splitLine: {
                            show: false
                        }
                    }
                ],
                dataset:{
                    dimensions:['fecha','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Crecimiento',
                        type: 'line',
                        // data: [10, 10, 14, 12, 15, 3, 7],
                        symbol: 'circle',
                        symbolSize: 10,
                        smooth: true,
                        yAxisIndex: 0,
                        showSymbol: false,
                        lineStyle: {
                            width: 5,
                            color: colorGeneral,
                            shadowColor: 'rgba(158,135,255, 0.3)',
                            shadowBlur: 10,
                            shadowOffsetY: 20
                        },
                        itemStyle: {
                            normal: {
                                color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: colorGeneral
                                },
                                {
                                    offset: 1,
                                    color: colorGeneral
                                }
                                ]),
                                borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: colorGeneral
                                },
                                {
                                    offset: 1,
                                    color: colorGeneral
                                }
                                ])
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#E9E9E9'
                                },
                                {
                                    offset: 1,
                                    color: '#F3F3F3'
                                }
                                ], false)
                            }
                        },
                    }
                ]
            },
            chart_pedidos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['distancia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            chart_tipos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            chart_cedis: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            clientes: [],
            leecheros_ventas: [],
            categorias_dinero: [],
            clientes_paginado: [],
            total_clientes: 0,
            total_clientes_paginado: 0,
            total_por_pagina: 0,
            semana:{},
            ultimo_mes:{},
            ultimo_trimestre:{},
            hoy:{},
            datos: true,
            compradores_edad: [
                { edad: '-20', estado: 1, cantidad: 6, porcentaje: 5, total : 0},
                { edad: '20-25', estado: 2, cantidad: 8 , porcentaje: 5, total : 0},
                { edad: '25-30', estado: 3, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '30-35', estado: 4, cantidad: 3 , porcentaje: 10, total : 0},
                { edad: '35-40', estado: 5, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '40-45', estado: 6, cantidad: 14 , porcentaje: 10, total : 0},
                { edad: '45-50', estado: 7, cantidad: 25 , porcentaje: 10, total : 0},
                { edad: '50-55', estado: 8, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '55-60', estado: 9, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '+60', estado: 10, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: 'Sin Determinar', estado: 11, cantidad: 12 , porcentaje: 10, total : 0},
            ],
            generos: [
                {
                    nombre: "Femenino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Masculino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Otro",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            dispositivos: [
                {
                    nombre: "Android",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "iOS",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Web",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            primeraVez: true,
            sin_tipo: false,
            metricas_created: null,
            metricas_entregas: null,
            id_tipo: null,
            ids_tipos: [],
            tiposClientes: [],
            tiposClientesSeleccionados: [],
            url_primera: '/img/ilustraciones/ingresos.svg',
            url_productos: '/img/ilustraciones/Productos.svg',
            primerosAccesos: [],
            primerosPedidos: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        id_team(){
            if(this.$usuario && (this.$usuario.rol == 2 || this.$usuario.rol == 4))return this.$tienda.id_team
            return this.$route.params.id_equipo
        },
        team(){
            return this.leecheros.length == 0
        },
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoMetricas &&
                    (this.$refs.grafica && !this.$refs.grafica.loading1) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading2) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading3)
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        filtro_relacion(val){
            this.calcularComparativos()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        tipo_fecha(){
            this.calcularComparativos()
            this.calcularInforme()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
                this.cargandoCrecimiento = true
            }
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        this.cargando()
                        await this.listaTiposClientesTeam()
                        await this.crecimiento()
                        await this.comparativos()
                        setTimeout( async() => {
                            await this.$refs.grafica.montar(this.ids_tipos, this.sin_tipo, this.leecheros, this.id_team, this.team)
                        }, 500);
                        await this.informe()
                    }, 1000);
                }else{
                    this.cargando()
                    await this.listaTiposClientesTeam()
                    await this.crecimiento()
                    await this.comparativos()
                    setTimeout( async() => {
                        await this.$refs.grafica.montar(this.ids_tipos, this.sin_tipo, this.leecheros, this.id_team, this.team)
                    }, 500);
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        estiloEdad(estado){
            switch(estado){
            case 1:
                return 'text-orange-clear_2'
            case 2:
                return 'text-green'
            case 3:
                return 'text-purple'
            case 4:
                return 'text-orange-clear'
            case 5:
                return 'text-dark-blue'
            case 6:
                return 'text-blue-light'
            case 7:
                return 'text-pink-light'
            case 8:
                return 'text-dark-green'
            case 9:
                return 'text-blue'
            case 10:
                return 'text-dark-pink'
            case 11:
                return 'text-dark'
            }
        },
        backgroundEdad(estado){
            switch(estado){
            case 1:
                return 'bg-orange-clear_2'
            case 2:
                return 'bg-green'
            case 3:
                return 'bg-purple'
            case 4:
                return 'bg-orange-clear'
            case 5:
                return 'bg-dark-blue'
            case 6:
                return 'bg-blue-light'
            case 7:
                return 'bg-pink-light'
            case 8:
                return 'bg-dark-green'
            case 9:
                return 'bg-blue'
            case 10:
                return 'bg-dark-pink'
            case 11:
                return 'bg-dark'
            /* case 10:
                return 'text-green' */
            }
        },
        iconFiltro(dato){
            if (this.clicksPedidos === dato){
                return this.order ? 'flecha-orden text-general abajo mover-abajo' : 'flecha-orden girar text-general arriba mover-arriba'
            } else {
                return 'text-gris'
            }
        },
        containerClass(dato){
            if (this.clicksPedidos === dato){
                return this.order ? ' text-general abajo' : 'girar text-general arriba'
            } else {
                return 'text-gris'
            }
        },
        AbrirInfoClientes(cliente){
            this.$refs.infoClientes.toggle(cliente,this.id_tienda);
        },
        ordenar(tipo){
            this.clicksPedidos = tipo
            let mismoTipo = tipo == this.clicksPedidos
            if (mismoTipo){
                this.order = !this.order
            }else{
                this.order = orden == 'desc'
            }
            let ordenar = this.order ? 'desc' : 'asc'
            switch (tipo){
            case 1:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 2:
                this.clientes_paginado = _.orderBy(this.clientes,'total_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 3:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 4:
                this.clientes_paginado = _.orderBy(this.clientes,'total_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 5:
                this.clientes_paginado = _.orderBy(this.clientes,'promedio_fecha',ordenar).slice(0,this.total_por_pagina)
                break;

            default:
                break;
            }
        },
        agregar_mas_clientes(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina += 20
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        verTodos(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina = this.total_clientes
                this.clientes_paginado = this.clientes
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        async crecimiento(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.crecimiento(params)
                this.chart_Crecimiento.dataset.source = data.data.clientes
                this.crecimientoClientes[0].valor = data.data.total_clientes
                this.crecimientoClientes[1].valor = data.data.crecimiento_mayor ? data.data.crecimiento_mayor.cantidad : 0
                this.crecimientoClientes[1].fecha = data.data.crecimiento_mayor ? data.data.crecimiento_mayor.fecha : 'No registra'

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoCrecimiento = false
            }
        },
        async listaTiposClientesTeam(){
            try {
                let params = {
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await Clientes.listaTiposClientesTeam(params)
                this.tiposClientes = data.tipos
                this.clientesCedis = data.clientesCedis
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async informe(){
            try {

                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas
                this.primerosAccesos = data.data.primerosAccesos
                this.primerosPedidos = data.data.primerosPedidos

                // this.notificacion('Mensaje','Informe Actualizado correctamente','success')
                this.total_por_pagina = 10

                this.calcularInforme()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        verCategorias(titulo){
            this.$refs.modalVerCategorias.toggle({ categorias: this.categorias_dinero, titulo});
        },
        ventasPorLeecheros(titulo){
            this.$refs.ventasPorLeecheros.toggle({ categorias: this.leecheros_ventas, titulo, pedidos: true});
        },
        calcularInforme(){
            try {
                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created

                this.chart_tipos.dataset.source = datos.tiposVentas
                this.chart_cedis.dataset.source = datos.cedisVentas
                this.chart_pedidos.dataset.source = datos.distancias
                this.pedidosRecibidos[0].valor = datos.distancia_total
                this.pedidosRecibidos[1].valor = datos.distancia_total_max ? datos.distancia_total_max.total : 0
                this.pedidosRecibidos[1].fecha = datos.distancia_total_max ? datos.distancia_total_max.distancia : 'No registra'
                this.pedidosRecibidos[2].valor = datos.distancia_cantidad
                this.pedidosRecibidos[3].valor = datos.distancia_cantidad_max ? datos.distancia_cantidad_max.cantidad : 0
                this.pedidosRecibidos[3].fecha = datos.distancia_cantidad_max ? datos.distancia_cantidad_max.distancia : 'No registra'
                this.pedidosTipos[0].valor = datos.maxTiposVentas.totalVentas
                this.pedidosTipos[1].valor = datos.maxTiposVentas.maxVentas ? datos.maxTiposVentas.maxVentas.total : 0
                this.pedidosTipos[1].fecha = datos.maxTiposVentas.maxVentas ? datos.maxTiposVentas.maxVentas.nombre : 'No registra'
                this.pedidosTipos[2].valor = datos.maxTiposVentas.totalCantidad
                this.pedidosTipos[3].valor = datos.maxTiposVentas.maxCantidad ? datos.maxTiposVentas.maxCantidad.cantidad : 0
                this.pedidosTipos[3].fecha = datos.maxTiposVentas.maxCantidad ? datos.maxTiposVentas.maxCantidad.nombre : 'No registra'
                this.pedidosCedis[0].valor = datos.maxCedisVentas.totalVentas
                this.pedidosCedis[1].valor = datos.maxCedisVentas.maxVentas ? datos.maxCedisVentas.maxVentas.total : 0
                this.pedidosCedis[1].fecha = datos.maxCedisVentas.maxVentas ? datos.maxCedisVentas.maxVentas.nombre : 'No registra'
                this.pedidosCedis[2].valor = datos.maxCedisVentas.totalCantidad
                this.pedidosCedis[3].valor = datos.maxCedisVentas.maxCantidad ? datos.maxCedisVentas.maxCantidad.cantidad : 0
                this.pedidosCedis[3].fecha = datos.maxCedisVentas.maxCantidad ? datos.maxCedisVentas.maxCantidad.nombre : 'No registra'
                this.clientes = datos.clientes
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes = datos.total_clientes
                this.total_clientes_paginado = this.clientes_paginado.length


                this.generos = datos.generos
                this.compradores_edad = datos.edades
                this.categorias_dinero = datos.categorias
                this.leecheros_ventas = datos.leecheros

                this.dispositivos = datos.dispositivos
            } catch (error){
                console.log(error);
            }
        },
        //comparativos
        modalRelacion(idx,ids,titulo){
            if (idx>1 && ids.length > 0){
                let filtro = ''
                switch (this.filtro_relacion){
                case 1:
                    filtro = "Durante última semana"
                    break;
                case 2:
                    filtro = "Durante último mes"
                    break;
                case 3:
                    filtro = "Durante último trimestre"
                    break;
                default:
                    break;
                }
                this.$refs.modalDetalleRelacion.toggle(ids,titulo,filtro)
            }
        },
        async comparativos(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.comparativos(params)
                this.$validar(data)

                this.semana_created = data.data.semana.pedidos_created
                this.ultimo_mes_created = data.data.ultimo_mes.pedidos_created
                this.hoy_created = data.data.hoy.pedidos_created
                this.semana_entregas = data.data.semana.pedidos_entregas
                this.ultimo_mes_entregas = data.data.ultimo_mes.pedidos_entregas
                this.hoy_entregas = data.data.hoy.pedidos_entregas

                this.calcularComparativos()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                this.semana = this.tipo_fecha == 1 ? this.semana_entregas : this.semana_created
                this.ultimo_mes = this.tipo_fecha == 1 ? this.ultimo_mes_entregas : this.ultimo_mes_created
                this.hoy = this.tipo_fecha == 1 ? this.hoy_entregas : this.hoy_created

                let tipo = {}
                switch (this.filtro_relacion){
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                case 4:
                    tipo = this.hoy
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.ventas.porcentaje
                this.relaciones[1].valor = tipo.ventas.valor
                this.relaciones[2].porcentaje = tipo.tenderos.porcentaje
                this.relaciones[2].valor = tipo.tenderos.valor
                this.relaciones[2].ids = tipo.tenderos.id
                this.relaciones[3].porcentaje = tipo.nuevos.porcentaje
                this.relaciones[3].valor = tipo.nuevos.valor
                this.relaciones[3].ids = tipo.nuevos.id
                this.relaciones[4].porcentaje = tipo.recurrentes.porcentaje
                this.relaciones[4].valor = tipo.recurrentes.valor
                this.relaciones[4].ids = tipo.recurrentes.id
                this.relaciones[5].porcentaje = tipo.perdidos.porcentaje
                this.relaciones[5].valor = tipo.perdidos.valor
                this.relaciones[5].ids = tipo.perdidos.id
            } catch (error){

            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.informe()

            } catch (error){
                console.log(error)
            }
        },
        async tiposRefrescar(){
            try {
                this.tipo = 1
                this.cargando()
                await this.montar()

            } catch (error){
                console.log(error)
            }
        },
        modalPrimeraVez(tipo){
            let ids = tipo == 1 ? this.primerosAccesos : this.primerosPedidos
            let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
            let fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            if (ids.length == 0) return
            this.$refs.modalCompraIngreso.toggle(ids, this.id_moneda, 2, fecha);
        },
        eliminarTipo(id){
            const spliced = this.tiposClientesSeleccionados.splice(id,1)
            this.ids_tipos.splice(id,1)
        },
        agregarTipos(val){
            let tipo = this.tiposClientes.find(el => el.id == val)
            this.ids_tipos.push(val)
            this.tiposClientesSeleccionados.push(tipo)
            this.id_tipo = null
        }
    }
}
</script>
<style lang="scss">
.br-12{
    border-radius: 12px;
}
.text-pink{
    color: #D6017B;
}
.text-blue{
    color:#0892DD;
}
.text-orange{
    color: #FF612B;
}
.text-purple{
    color: #9508EB;
}

.text-orange-clear_2{ color: #FFB87A;}
.text-orange-clear{ color: #FFDF75; }
.text-green{ color: #02D6BC;}
.text-dark-blue{ color: #284367;}
.text-blue-light{ color: #0892DD ;}
.text-pink-light{ color:#FFB1D0; }
.text-dark-green{ color: #03BB83;}
.text-blue{ color: #6BD1FF;}
.text-dark-pink{ color:#E33FC3;}

.bg-orange-clear_2{ background-color: #FFB87A;}
.bg-orange-clear{ background-color: #FFDF75; }
.bg-green{ background-color: #02D6BC;}
.bg-dark-blue{ background-color: #284367;}
.bg-blue-light{ background-color: #0892DD ;}
.bg-pink-light{ background-color:#FFB1D0; }
.bg-dark-green{ background-color: #03BB83;}
.bg-blue{ background-color: #6BD1FF;}
.bg-dark-pink{ background-color:#E33FC3;}


.bg-blue-clear{background-color: #03D6BC;}
.bg-blue-dark{ background-color: #284367;}
.bg-dark{ background-color: #333435;}
.text-dark{ color: #333435;}
.text-blue-clear{ color: #03D6BC; }
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}
.height-vista{
    height: calc(100vh - 400px)
}
.card-ventas{
    width: 90px;
}
</style>
